























































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab5Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Molecule A', value: 'A'},
        {text: 'Molecule B', value: 'B'},
        {text: 'Molecule C', value: 'C'},
        {text: 'Molecule D', value: 'D'},
        {text: 'Molecule E', value: 'E'},
        {text: 'Molecule F', value: 'F'},
      ],
      optionsFr: [
        {text: 'Molécule A', value: 'A'},
        {text: 'Molécule B', value: 'B'},
        {text: 'Molécule C', value: 'C'},
        {text: 'Molécule D', value: 'D'},
        {text: 'Molécule E', value: 'E'},
        {text: 'Molécule F', value: 'F'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/brominationOfCyclohexeneOptions.png';
    },
  },
};
